<template>
  <v-data-table dense class="row-height" width="100%" :headers="symbol_ticker_header" :items="symboltickers" sort-by="symbol"
    disable-pagination :items-per-page="NaN" hide-default-footer>
    <template v-slot:item.symbol="{ item }">
      <router-link :to="'/pages/market/' + item.symbol">{{
          item.symbol
      }}</router-link>
    </template>

    <template v-slot:item.priceChangePercent="{ item }">
      <div :style="`color: ${item.priceChangePercent > 0 ? 'green' : 'red'}`">{{ item.priceChangePercent }}%</div>
    </template>

    <template v-slot:item.lastPrice="{ item }">
      <div :style="`color: ${item.lastPrice > item.openPrice ? 'green' : 'red'}`">{{ item.lastPrice }}</div>
    </template>

    <template v-slot:item.lastQty="{ item }">
      <div :style="`color: ${item.lastPrice > item.openPrice ? 'green' : 'red'}`">{{ item.lastQty }}</div>
    </template>

    <template v-slot:item.highPrice="{ item }">
      <div :style="`color: green`">{{ item.highPrice }}</div>
    </template>

    <template v-slot:item.lowPrice="{ item }">
      <div :style="`color: red`">{{ item.lowPrice }}</div>
    </template>

    <template v-slot:item.updateTime="{ item }">
      {{ new Date(item.updateTime).toLocaleString() }}
    </template>
  </v-data-table>
</template>

<script>
export default {
  name: "SymbolTickerList",

  props: {
    symboltickers: Array,
  },

  data() {
    return {
      symbol_ticker_header: [
        { text: "Symbol", value: "symbol" },
        { text: "Acc Type", value: "accountType", sortable: false },
        { text: "Pct", value: "priceChangePercent" },
        { text: "Last Price", value: "lastPrice" },
        { text: "Last Qty", value: "lastQty" },
        { text: "Open Price", value: "openPrice" },
        { text: "High Price", value: "highPrice" },
        { text: "Low Price", value: "lowPrice" },
        { text: "Volume", value: "volume" },
        { text: "Update Time", value: "updateTime", sortable: false },
      ],
    };
  },
};
</script>

<style scoped lang="scss">
.v-data-table.row-height td {
  height: 20px !important;
  font-size: 14px !important;
}
</style>
