<template>
  <v-container id="market" fluid tag="section">
<v-tabs>
      <v-tab key="BINANCE_USDT_FUTURE">BINANCE_USDT_FUTURE</v-tab>
      <v-tab key="BINANCE_SPOT">BINANCE_SPOT</v-tab>

        <v-tab-item key="BINANCE_USDT_FUTURE"
        >
        <symbol-ticker-list :symboltickers="futuresTickerList"></symbol-ticker-list>
        </v-tab-item>

        <v-tab-item key="BINANCE_SPOT"
        >
        <symbol-ticker-list :symboltickers="spotTickerList"></symbol-ticker-list>
        </v-tab-item>
    </v-tabs>
    
  </v-container>
</template>

<script>

import { 
  get_sorted_value_list,
} from '@/utils'

import SymbolTickerList from "@/views/dashboard/component/SymbolTickerList";

export default {
  components: {
    SymbolTickerList,
  },

  destroyed() {
    clearInterval(this.timer);
  },

  mounted: async function () {

    this.sockets.subscribe("SymbolTicker", (event) => {
      const ev = JSON.parse(event);
      const symbol = ev.symbol;
      const accountType = ev.accountType;

      if (accountType === 'BINANCE_USDT_FUTURE' || accountType === 'BINANCE_USDT_FUTURE_TEST') {
        this.futuresTickerDatas[symbol] = ev;
      } else {
        this.spotTickerDatas[symbol] = ev;
      }
      
    });

    this.timer = setInterval(() => {

      this.futuresTickerList = this.genTickerList(this.futuresTickerDatas)
      this.spotTickerList = this.genTickerList(this.spotTickerDatas)

      this.$forceUpdate();

    }, 1000);

  },

  watch: {
  },

  data() {
    return {
      futuresTickerList: [],
      spotTickerList: [],
      futuresTickerDatas: {},
      spotTickerDatas: {},
      timer: -1,
    };
  },

  methods: {
    genTickerList(rawData) {
      return get_sorted_value_list(rawData);
    }
  },
};
</script>
